.header 
    width: 100%
    height: 64px
    background-color: var(--white)
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4)
    display: flex
    align-items: center
    position: fixed
    top: 0
    z-index: 2
    &__nav 
        width: 100%
    &__list 
        display: flex
        justify-content: space-around
        align-items: center
        list-style: none
        font-size: 18px
    &__wrapper
        padding: 8px
        height: 100%
    &__logo
        height: 100%
    &__logo_mobile
        display: none
    a
        color: var(--black)
    a:hover
        color: var(--blue)

@media screen and (max-width: 576px) 
    .header
        &__wrapper
            padding: 0
            height: 100%
        &__logo
            display: none
        &__logo_mobile
            display: block
            height: 100%
            position: absolute
            left: 50%
            transform: translateX(-50%)
            padding-block: 4px
        &__list_mobile
            height: 0
            width: 100%
            flex-direction: column
            position: absolute
            left: 0
            top: 64px
            background: var(--white)
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4)
            transition: all 0.5s
            overflow: hidden
            &_active
                height: 200px
        &__hamburger
            width: 24px
            height: 24px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: absolute
            top: 20px
            right: 20px
            div
                width: 100%
                height: 2px
                background: var(--black)
                transition: all 0.5s
            &_active
                div
                    &:nth-child(1)
                        width: 34px
                        transform: rotateZ(45deg) translate(5px, 10px)
                    &:nth-child(2)
                        opacity: 0
                    &:nth-child(3)
                        width: 34px
                        transform: rotateZ(-45deg) translate(5px, -10px)
                        