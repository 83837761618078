.about, .solutions, .advetisers
    min-height: calc(100dvh - 64px)
    padding: 32px 0
.promo, .solutions, .contacts
    background: var(--white)
.about, .advetisers
    background: url("../../Images/bg.jpg")
.about, .advetisers
    .text, .title, .subtitle
        color: var(--white)
.promo
    height: 100dvh
    padding-top: 64px
    background: url("../../Images/promo_2.jpg") center top/cover no-repeat
.advetisers 
    .box 
        &__wrapper 
            gap: 64px
.contacts
    height: 160px
    .box 
        &__wrapper 
            justify-content: space-between
.main__wrapper 
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: start
    gap: 24px
    position: relative
.main__logo
    width: 100%
    border-radius: 12px
.title 
    font-size: 32px

.verticals 
    .text, .title 
        margin-top: 24px
.subtitle 
    font-size: 20px
.text 
    text-align: justify
    line-height: 1.5rem
.solutions 
    .box 
        max-width: 378px
.extra
    margin: 0 auto
    text-align: center
    &__text
        font-size: 20px
        font-weight: 700
        margin-bottom: 16px
.box 
    max-width: 480px
    display: flex
    flex-direction: column
    gap: 8px
    &__wrapper 
        width: 100%
        display: flex
        justify-content: center
        flex-direction: row
        flex-wrap: wrap
        gap: 32px
        margin-top: 24px
.contacts
    &__form, &__address
        width: 560px
        display: flex
        flex-direction: column
        gap: 24px
    &__input
        display: flex
        flex-direction: column
        gap: 4px
        label
            font-size: 18px
        input, textarea
            height: 40px
            font-size: 16px
            padding: 8px 12px
            outline: none
            border: none
            border-radius: 12px
            color: var(--white)
            background: var(--black)
        textarea
            height: 200px
            resize: none
    &__btn
        width: 100px
        height: 40px
        font-size: 16px
        font-weight: 700
        letter-spacing: 0.1rem
        outline: none
        border: none
        border-radius: 12px
        color: var(--white)
        background: var(--blue)
        text-transform: uppercase
        cursor: pointer
        &__wrapper
            display: flex
            justify-content: flex-end
    iframe
        border: none
        width: 100%
        height: 400px
    &__footer
        width: 100%
        height: 40px
        display: flex
        justify-content: space-between
        position: absolute
        bottom: 0
        left: 0

@media screen and (max-width: 1200px) 
    .about, .solutions, .advetisers, .contacts
        height: auto
    .main__wrapper 
        padding: 32px 32px
    .contacts
        .box__wrapper
            flex-wrap: nowrap
        &__form, &__address
            width: 100%
        &__footer
            padding: 0 32px
@media screen and (max-width: 900px) 
    .solutions
        .box 
            max-width: 344px
            &__wrapper
                gap: 16px
@media screen and (max-width: 768px)
    .promo
        height: 500px
        background-position: center top
    .contacts
        .main__wrapper 
            padding: 32px 32px 96px
        .box__wrapper
            flex-direction: column
        &__form, &__address
            width: 100%
        &__footer
            padding: 0 32px
            display: flex
            flex-direction: column
            gap: 16px
            bottom: 32px
    .solutions
        .box 
            max-width: 100%
    .box 
        max-width: 100%
    .advetisers 
        .box 
            padding-left: 20px