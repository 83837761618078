.privacy-policy 
    background-color: var(--white)
    padding: 32px
    &__wrapper 
        display: flex
        flex-direction: column
        gap: 24px
    &__text
        margin-top: 12px
        line-height: 1.5rem
    body
        padding: 0
