* 
    margin: 0
    padding: 0
    font-family: 'Roboto', sans-serif
    color: var(--black)
    box-sizing: border-box
    -ms-user-select: none
    -moz-user-select: none
    user-select: none
html 
    scroll-behavior: smooth
    scroll-padding-top: 64px
body 
    background: var(--blue)
a 
    color: var(--blue)
    text-decoration: none
    transition: all 0.1s
a:hover 
    color: var(--black)
::-webkit-scrollbar 
    width: 8px
    background: var(--white)
::-webkit-scrollbar-thumb 
    background: var(--black)
    border-radius: 4px
.container 
    max-width: 1200px
    margin: 0 auto
